<template>
  <div 
    :class="
    [
      isOdd ? '' : '',
      'polygon-container',
      isMobile ? 'mobile': ''
    ]"
    ref="polygonContainer"
    :style="{height: computedWidth }">
    <!-- (TEXTOS + CHECK Y CROSS) OR ICOM-->
      <template v-if="polygonWithIcon">
        <img 
          :class="
          [
            'absolute',
            'check',
            isMobile ? 'cross-check-mobile' : (isOdd ? 'check-left' : 'check-right-icon')
          ]" 
          :src="polygonWithIcon" />
      </template>
      <template v-else>
        <img v-if="filledPercent == '100'" 
          :class="
          [
            'absolute',
            'check',
            isMobile ? 'cross-check-mobile' : (isOdd ? 'check-left' : 'check-right')
          ]" 
          src="assets/ic-check.svg" />


        <div v-if="filledPercent != '100'"
          :class="
          [
            'absolute',
            'text-on-top',
            isMobile ? 'text-mobile' : 
              (isOdd ? 
                (filledPercent < 10 ? 'text-on-top-right-single' : 'text-on-top-right') : 
                (filledPercent < 10 ? 'text-on-top-left-single' : 'text-on-top-left')
              )
          ]">
          {{ filledPercent}}
          <span class="text-relative">%</span>
        </div>
      </template>

      <!-- momentáneamente se elimina la cruz para dejar el número 0 
      <img v-if="filledPercent == '0' || filledPercent == '00'" 
        :class="
        [
          'absolute',
          'cross',
          isMobile ? 'cross-check-mobile' : (isOdd ? 'cross-left' : 'cross-right'),
        ]" 
        src="assets/ic-cross.svg" />
      -->
    <!---------------------------------------------------------------------------------->


    <!-- Imágenes del polygono + fill inside según porcentaje -->

      <img 
        :class="
        [
          'absolute',
          'img-top',
          isMobile ? 'img-polygon-mobile-position img-width-mobile' : (isOdd ? '' : 'polygon-right')
        ]"
        :src="`assets/tokenfit-${stepDecorator ? `${stepDecorator}-`: ''}step-${step}.svg`"/>


      <img 
        :class="
        [
          'absolute',
          'img-middle',
          isMobile ? 'img-polygon-mobile-position img-width-mobile' : (isOdd ? '' : 'polygon-right')
        ]"
        :src="`assets/tokenfit-${stepDecorator ? `${stepDecorator}-`: ''}step-${step}-fill.svg`"
        :style="{
          'clip-path':`inset(${100 - filledPercent}% 0px 0px 0px)`
        }" />


      <img 
        :class="
        [
          'absolute',
          'img-back',
          isMobile ? 'img-polygon-mobile-position img-width-mobile' : (isOdd ? '' : 'polygon-right')
        ]" 
        :src="`assets/tokenfit-${stepDecorator ? `${stepDecorator}-`: ''}step-${step}-back.svg`"/>

    <!----------------------------------------------------------------------------->

    <!-- BACKGROUND SEGUN DESKTOP O MOBILE -->

      <img 
        v-if="!isMobile"
        :class="
        [
          'background',
          isOdd ? 'background-left' : 'background-left-even'
        ]" 
        :src="`assets/tokenfit-bg-step-${step}.png`"
        @load="$emit('load')"  />

      <img
        ref="backMobile" 
        v-if="isMobile"
        class="absolute background img-back-mobile" 
        :src="`assets/tokenfit-bg-step-${step}-mobile.png`"
        @load="$emit('load')"  />

  </div>
</template>

<script>
export default {
  name: "PolygonStyleComponent",
  props: {
    step: {
      type: String,
      default: "04",
      required: true
    },
    filledPercent: {
      type: String,
      default: "100",
      required: true
    },
    isOdd: {
      type: Boolean,
      default: true
    },
    polygonWithIcon: {
      type: String,
      default: undefined
    },
    stepDecorator: {
      type: String,
      default: undefined
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    computedWidth() {
      return this.isMobile && this.$refs.polygonContainer ? this.$refs.polygonContainer.offsetWidth * 0.5 : undefined
    }
  }
}
</script>

<style lang="scss">
$width: 10em;
$height: $width*1.14;
.absolute {
  position: absolute;
}
.mobile {
  justify-content: center;
  margin-left: 0 !important;
  margin-right: 0 !important;
  /*height: $height * 1.8 !important;*/
  height: 26.5vw !important;
  width: 100% !important;
}
.cross-check-mobile {
  top: 3em;
} 


.img-polygon-mobile-position {
  top: 10px;
}
.text-mobile { 
  top: 2.3em;
  text-align: center;
  font-size: 2.3em !important;
}
@media (max-width: 1150px){
  .img-polygon-mobile-position {
    top: 0px !important;
  }
  .text-mobile { 
    top: 2em !important;
  }
}
@media (max-width: 1050px){
  .img-polygon-mobile-position {
    top: -6px !important;
  }
  .text-mobile { 
    top: 1.8em !important;
  }
  
}
@media (max-width: 950px){
  .img-polygon-mobile-position {
    top: -12px !important;
  }
  .text-mobile { 
    top: 1.6em !important;
  }
  .cross-check-mobile {
    top: 2.8em;
  } 
}
@media (max-width: 850px){
  .img-polygon-mobile-position {
    top: -20px !important;
  }
  .text-mobile { 
    top: 1.3em !important;
  }
  .cross-check-mobile {
    top: 1.8em;
  }   
}
@media (max-width: 750px){
  .img-polygon-mobile-position {
    top: -30px !important;
  }
  .text-mobile { 
    top: 1.0em !important;
  }
  .cross-check-mobile {
    top: 1.5em;
  }  
}
@media (max-width: 650px){
  .img-polygon-mobile-position {
    top: -40px !important;
  }
  .text-mobile { 
    top: .7em !important;
  }
  .cross-check-mobile {
    top: 1em;
  }   
}
@media (max-width: 500px){
  .img-polygon-mobile-position {
    top: -50px !important;
  }
  .text-mobile { 
    top: .35em !important;
  }
    .cross-check-mobile {
    top: 0.5em;
    width: 3rem !important;
  }
}
@media (max-width: 400px){
  .img-polygon-mobile-position {
    top: -58px !important;
  }
  .text-mobile { 
    font-size: 8vw !important;
    top: .5em !important;
  }
}
@media (max-width: 300px){
  .img-polygon-mobile-position {
    top: -64px !important;
  }
  .text-mobile { 
    top: .5em !important;
  }
}
@media (max-width: 250px){
  .text-mobile { 
    top: .7em !important;
  }
}
@media (max-width: 200px){
  .img-polygon-mobile-position {
    top: -70px !important;
  }

}


.img-top {
  z-index: 3;
  width: $width;
  height: $height;
}
.img-middle {
  z-index: 2;
  width: $width;
  height: $height;
}
.img-back {
  z-index: 1;
  width: $width;
  height: $height;
}
.img-back-mobile {
  top: 25%;
  width: 100%;
}
.background {
  z-index: 0;
}
.polygon-container {
  display: flex;
  width: $height;
  height: 100%;
  align-items: center;
  position: relative;
}
.polygon-right {
  right: 0;
}
.background-left {
  margin-left: 4.1em;
}
.background-left-even {
  margin-left: -4px;
}
.text-on-top {
  z-index: 4;
  width: 100%;
  display: block;
  font-size: 2.5em;
  font-weight: 800;
}
.text-on-top-left {
    left: 0.5em;
}
.text-on-top-right {

}
.text-on-top-left-single {
    left: 0.5em;
}
.text-on-top-right-single {

}
.text-on-top .text-relative {
  font-size: .8em;
}
.check {
  z-index: 4;
  width: 4em;
  
}
.cross {
  z-index: 4;
  width: 3em;
  
}
.check-left {
  margin-left: 3em;
}
.cross-left {
  margin-left: 3.5em;
}
.check-right {
  margin-left: 4.5em;
}
.check-right-icon {
  margin-left: 4em;
}
.cross-right {
  margin-left: 5em;
}
.img-width-mobile {
  max-width: 27vw !important;
}
</style>