var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"polygonContainer",class:[
    _vm.isOdd ? '' : '',
    'polygon-container',
    _vm.isMobile ? 'mobile': ''
  ],style:({height: _vm.computedWidth })},[(_vm.polygonWithIcon)?[_c('img',{class:[
          'absolute',
          'check',
          _vm.isMobile ? 'cross-check-mobile' : (_vm.isOdd ? 'check-left' : 'check-right-icon')
        ],attrs:{"src":_vm.polygonWithIcon}})]:[(_vm.filledPercent == '100')?_c('img',{class:[
          'absolute',
          'check',
          _vm.isMobile ? 'cross-check-mobile' : (_vm.isOdd ? 'check-left' : 'check-right')
        ],attrs:{"src":"assets/ic-check.svg"}}):_vm._e(),(_vm.filledPercent != '100')?_c('div',{class:[
          'absolute',
          'text-on-top',
          _vm.isMobile ? 'text-mobile' : 
            (_vm.isOdd ? 
              (_vm.filledPercent < 10 ? 'text-on-top-right-single' : 'text-on-top-right') : 
              (_vm.filledPercent < 10 ? 'text-on-top-left-single' : 'text-on-top-left')
            )
        ]},[_vm._v(" "+_vm._s(_vm.filledPercent)+" "),_c('span',{staticClass:"text-relative"},[_vm._v("%")])]):_vm._e()],_c('img',{class:[
        'absolute',
        'img-top',
        _vm.isMobile ? 'img-polygon-mobile-position img-width-mobile' : (_vm.isOdd ? '' : 'polygon-right')
      ],attrs:{"src":("assets/tokenfit-" + (_vm.stepDecorator ? (_vm.stepDecorator + "-"): '') + "step-" + _vm.step + ".svg")}}),_c('img',{class:[
        'absolute',
        'img-middle',
        _vm.isMobile ? 'img-polygon-mobile-position img-width-mobile' : (_vm.isOdd ? '' : 'polygon-right')
      ],style:({
        'clip-path':("inset(" + (100 - _vm.filledPercent) + "% 0px 0px 0px)")
      }),attrs:{"src":("assets/tokenfit-" + (_vm.stepDecorator ? (_vm.stepDecorator + "-"): '') + "step-" + _vm.step + "-fill.svg")}}),_c('img',{class:[
        'absolute',
        'img-back',
        _vm.isMobile ? 'img-polygon-mobile-position img-width-mobile' : (_vm.isOdd ? '' : 'polygon-right')
      ],attrs:{"src":("assets/tokenfit-" + (_vm.stepDecorator ? (_vm.stepDecorator + "-"): '') + "step-" + _vm.step + "-back.svg")}}),(!_vm.isMobile)?_c('img',{class:[
        'background',
        _vm.isOdd ? 'background-left' : 'background-left-even'
      ],attrs:{"src":("assets/tokenfit-bg-step-" + _vm.step + ".png")},on:{"load":function($event){return _vm.$emit('load')}}}):_vm._e(),(_vm.isMobile)?_c('img',{ref:"backMobile",staticClass:"absolute background img-back-mobile",attrs:{"src":("assets/tokenfit-bg-step-" + _vm.step + "-mobile.png")},on:{"load":function($event){return _vm.$emit('load')}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }